import inlineCss from 'plugins/element/inline_css';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function getScrollParent(node) {
  if (!isVariableDefinedNotNull(node) || !(node instanceof HTMLElement)) {
    return null;
  }

  const style = window.getComputedStyle(node);
  const marginTop = parseInt(style.marginTop, 10);
  const marginBottom = parseInt(style.marginBottom, 10);
  const paddingTop = parseInt(style.paddingTop, 10);
  const paddingBottom = parseInt(style.paddingBottom, 10);
  const originalInlineCss = node.style.cssText;
  const temporaryInlineCss = {};

  if (paddingTop === 0) {
    temporaryInlineCss.paddingTop = '1px';
    temporaryInlineCss.marginTop = `${marginTop - 1}px`;
  }

  if (paddingBottom === 0) {
    temporaryInlineCss.paddingBottom = '1px';
    temporaryInlineCss.marginBottom = `${marginBottom - 1}px`;
  }

  inlineCss(node, temporaryInlineCss);

  if (node.scrollHeight > node.clientHeight) {
    node.style.cssText = originalInlineCss;
    return node;
  }

  node.style.cssText = originalInlineCss;
  return getScrollParent(node.parentNode);
}

export default getScrollParent;
