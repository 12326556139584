import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import getScrollParent from 'plugins/utilities/get_scroll_parent';

function scrollTop({ value = null, element = null, scrollParentFromElement = null } = {}) {
  const e = element;

  if (e) {
    if (isVariableDefinedNotNull(value)) {
      e.scrollTop = value;
    }

    return e.scrollTop;
  }

  if (isVariableDefinedNotNull(scrollParentFromElement)) {
    const scrollParent = getScrollParent(scrollParentFromElement);

    if (isVariableDefinedNotNull(scrollParent) && scrollParent !== document.body) {
      if (isVariableDefinedNotNull(value)) {
        scrollParent.scrollTop = value;
      }

      return scrollParent.scrollTop;
    }
  }

  if (isVariableDefinedNotNull(value)) {
    document.documentElement.scrollTop = value;
    document.body.parentNode.scrollTop = value;
    document.body.scrollTop = value;
  }

  return (
    document.documentElement.scrollTop ||
    document.body.parentNode.scrollTop ||
    document.body.scrollTop
  );
}

export default scrollTop;
